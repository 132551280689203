













































































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { setTableHeaderWidth, subtract } from '@/utils/index';
import AliOss from '@/utils/alioss';
import {
  coccMtdsResource_1_project_constructsByConstructSequenceNbrBidDetail,
  coccMtdsResource_1_project_constructsBySequenceNbrReportTree,
  downloadConstructZip,
} from '@/api/projectDetail';
import {
  constructCompositeBidDetail,
  downloadProcess,
  exportApprove,
  exportCancel,
  exportConstructDetailUrl,
  exportInit,
  getExportApproveResult,
  getExportTimes,
} from '@/api/export';
import { ExportApproveStatus, ReportTreeItem } from '@/interface/product';
import { PdfPageType } from '@/interface/pdfEdit';
import cloneDeep from 'lodash/cloneDeep';
import PdfEditor from './pdfEditor.vue';
const DEFAULT_EXPORT_NUM = 2; // 默认导出次数
let projectZipName = ''; // 避免其他页面的下载文件在当前页面下载时，名称取成当前名称
const columns = [
  {
    title: '名称',
    dataIndex: 'reportName',
    key: 'reportName',
  },
  {
    title: '样式状态',
    dataIndex: 'storageFormat',
    key: 'storageFormat',
    align: 'center',
    scopedSlots: { customRender: 'storageFormat' },
    width: 200,
  },
];
export default Vue.extend({
  name: 'exportTender',
  components: { PdfEditor },
  props: {
    isDownload: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
    },
  },
  data() {
    const projectTree: ReportTreeItem[] = [];
    const originalProjectTree: ReportTreeItem[] = [];
    const styleChangeList: ReportTreeItem[] = [];
    const selectedKeys: string[] = [];
    const expandedKeys: string[] = [];
    const treeSelectedKeys: string[] = []; // 只做选中初始化用
    return {
      projectTree,
      selectedKeys,
      expandedKeys,
      treeSelectedKeys,
      originalProjectTree,
      columns,
      data: [],
      tableHeadHeight: 0,
      loading: false, // 渲染前加载
      isMergeLoading: false, // 替换并重新计价loading
      fileUrl: '',
      spaceId: '', // 项目空间id
      isRequestEnd: false, // 是否请求完毕
      exportSpaceTimes: 0, // 权益导出次数
      exportApproveStatus: ExportApproveStatus.NOT, // 导出审核状态
      tipVisible: false,
      exportVisible: false, // 导出动画弹框
      tipLoading: false,
      autoExpandParent: true,
      modalTitle: '导出次数不足', // 导出弹框提示标题
      okText: '保存并立即订购', // 导出弹框确认按钮文案
      styleChangeList, // 点击切换样式展示数据列表
      styleChangeVisible: false, // 点击切换样式展示弹框
      selectedRowKeys: [] as string[],
      selectedRows: [] as ReportTreeItem[],
      currentTime: 0,
      start: false, // 是否停止轮询
      timer: 0,
      editorVisible: false, // 是否打开PDF编辑框
      currentInfo: null as any,
      allCheckedNum: 0, // 全选的数量
      initVisible: false, // 报表初始化二次确认
      setStyleOriginData: {}, // 设置横竖版原数据
      dropVisible: false,
      sequenceNbr: '', // 招标ID
      downloadType: '2', // 0 ：导出次数不足 1：购买次数不足 2 可正常下载
      projectSource: '', // 所属来源 1 招标通
      isFullScreen: false, // 是否全屏展示
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.getReportFormTree();
        this.downloadInit();
        this.setDialogHeight();
        window.addEventListener('resize', this.setFullScreen);
      } else {
        window.removeEventListener('resize', this.setFullScreen, false);
      }
    },
    styleChangeVisible: function (val) {
      if (val) {
        window.addEventListener('resize', this.getTableHeaderWidth);
      } else {
        window.removeEventListener('resize', this.getTableHeaderWidth, false);
      }
    },
    isExportMinimality: function (val) {
      const exportProcess = this.exportProcess;
      if (!val && exportProcess < 100 && exportProcess >= 0) {
        this.exportVisible = true;
      }
    },
    isExportGenerateList: {
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.exportGenerateListChangeInit();
        });
      },
      immediate: true,
    },
  },
  computed: {
    exportProcess(): number {
      return this.$store.state.project.exportProcess;
    },
    ...mapGetters([
      'refreshTreeFrom',
      'currentTreeInfo',
      'projectSequenceNbr',
      'projectBidSequenceNbr',
      'bidSequenceNbr',
      'projectSpaceDetail',
      'userInfo',
      'isThereReplace',
      'projectName',
      'listGenerating',
      'isExportMinimality',
      'getExportProcess',
      'isExportGenerateList',
      'readOnly',
      'combinedPriceStatus',
    ]),
    isEdit(): boolean {
      const types = [] as string[];
      type Types = keyof typeof PdfPageType;
      let key: Types;
      for (key in PdfPageType) {
        types.push(PdfPageType[key]);
      }
      return types.includes(this.currentInfo?.itemCategory);
    },

    /**
     * 剩余免费导出次数
     */
    freeExportSurplusNum(): number {
      const projectSpaceDetail = this.projectSpaceDetail;
      if (!projectSpaceDetail) return 0;
      if (this.isExperience) return 0;
      if (this.downloadType !== '2') return 0;
      const exportNumber = projectSpaceDetail.exportNumber;
      const num = Number(
        subtract(DEFAULT_EXPORT_NUM, exportNumber < 0 ? 0 : exportNumber)
      );
      return num <= 0 ? 0 : num;
    },
    /**
     * 是否为管理员
     */
    isAdmin(): boolean {
      return (
        (this.userInfo?.agencyType && this.projectSource !== '1') ||
        this.projectSource === '1'
      );
    },
    /**
     * 审核成功
     */
    approveSuccess(): boolean {
      return (
        this.exportApproveStatus === ExportApproveStatus.SUCCESS &&
        !this.freeExportSurplusNum
      );
    },
    /**
     * 审核失败驳回
     */
    approveFail(): boolean {
      return this.exportApproveStatus === ExportApproveStatus.FAIL;
    },
    /**
     * 审核中
     */
    approveWait(): boolean {
      return this.exportApproveStatus === ExportApproveStatus.WAIT;
    },
    /**
     * 已作废
     */
    approveCancel(): boolean {
      return this.exportApproveStatus === ExportApproveStatus.CANCEL;
    },
    /**
     * 是否可申请
     * 不是管理 + 审核状态为失败或者未申请 + 没有免费导出次数
     */
    isApply(): boolean {
      if (!this.isRequestEnd) return false;
      return (
        !this.isAdmin &&
        this.userInfo.permissionType === '2' &&
        (this.approveFail ||
          this.exportApproveStatus === ExportApproveStatus.NOT ||
          this.approveCancel) &&
        !this.freeExportSurplusNum
      );
    },
    isDisabledDownload(): boolean {
      if (!this.isRequestEnd) return true;
      if (
        !this.isAdmin &&
        this.exportApproveStatus !== ExportApproveStatus.SUCCESS &&
        !this.freeExportSurplusNum
      ) {
        return true;
      }
      if (this.isAdmin && this.downloadType === '1') {
        return true;
      }
      if (
        this.isAdmin &&
        !this.freeExportSurplusNum &&
        !this.exportSpaceTimes
      ) {
        return true;
      }
      return !this.isDownload;
    },
    dialogScroll() {
      const num: number = 500 - this.tableHeadHeight;
      return { y: num };
    },
    /**
     * 是否体验项目
     */
    isExperience(): boolean {
      const projectSpaceDetail = this.projectSpaceDetail;
      if (!projectSpaceDetail) return false;
      return !!projectSpaceDetail.projectSpaceStatus;
    },
    /**
     * 头部身份信息
     */
    text() {
      if ((this.userInfo as any).userType === 'Enterprise') {
        return '所属企业';
      } else if ((this.userInfo as any).userType === 'Personal') {
        return '当前个人';
      } else {
        return '当前';
      }
    },
  },
  mounted() {
    this.spaceId = this.$route.query?.spaceId as string;
    this.sequenceNbr = this.$route.query?.sequenceNbr as string;
    this.projectSource = this.$route.query?.projectSource as string;
  },
  methods: {
    ...mapMutations([
      'SET_EXPORT_NUMBER',
      'SET_EXPORT_PROCESS',
      'SET_IS_EXPORT_GENERATE_LIST',
      'SET_GENERATELISTSTATUS',
      'addCurrentExportList',
      'removeCurrentExportList',
      'EXPORT_PROCESS_STATUS_INIT',
    ]),
    ...mapActions(['syncTriggerGenerationPdf', 'projectOperationStatusInit']),
    openFullscreen() {
      /* 获取(<html>)元素以全屏显示页面 */
      const full = document.getElementsByClassName('export-dialog')[0] as any;
      if (full) {
        this.isFullScreen = true;
      }
      if (full.RequestFullScreen) {
        full.RequestFullScreen();
        //兼容Firefox
      } else if (full.mozRequestFullScreen) {
        full.mozRequestFullScreen();
        //兼容Chrome, Safari and Opera等
      } else if (full.webkitRequestFullScreen) {
        full.webkitRequestFullScreen();
        //兼容IE/Edge
      } else if (full.msRequestFullscreen) {
        full.msRequestFullscreen();
      }
    },
    setFullScreen() {
      if (document.fullscreenElement) {
        console.log('进入全屏');
      } else {
        console.log('退出全屏');
        this.isFullScreen = false;
      }
    },
    containerChange() {
      if (this.isFullScreen) {
        return document.getElementsByClassName('full-dialog')[0];
      } else {
        return document.getElementsByClassName('export-dialog')[0];
      }
    },
    closeFullscreen() {
      document.exitFullscreen();
      this.isFullScreen = false;
    },
    /**
     * 新进入工程在导出清单生成中时执行
     */
    exportGenerateListChangeInit() {
      if (
        !this.exportVisible &&
        !this.isExportMinimality &&
        this.isExportGenerateList
      ) {
        this.minimality();
        this.startProcess();
      }
    },
    /**
     * 编辑成功回调
     */
    successCallback() {
      this.fileUrl = '';
      this.getReportFormTree();
      // setTimeout(() => {

      // }, 100);
    },
    /**
     * 初始进入页面获取各种状态
     */
    async downloadInit() {
      this.exportSpaceTimes = 0;
      this.isRequestEnd = false;
      this.exportApproveStatus = ExportApproveStatus.NOT;
      this.getExportSpaceTimes();
      await this.syncGetExportApproveResult();
      if (this.projectSource === '1') {
        await this.downloadConstructZip();
      }
      this.isRequestEnd = true;
    },
    // 招标通项目下载
    downloadConstructZip() {
      downloadConstructZip(this.sequenceNbr).then((res: any) => {
        if (res.status === 200) {
          this.downloadType = res.result;
        }
        console.log('res', res);
      });
    },
    /**
     * 获取报表树数据
     */
    getReportFormTree() {
      if (!this.projectBidSequenceNbr) return '';
      coccMtdsResource_1_project_constructsBySequenceNbrReportTree(
        this.projectBidSequenceNbr
      )
        .then((res) => {
          if (res.status === 200) {
            const projectTree = this.handlerReportTree([res.result]);
            this.projectTree = projectTree;
            this.originalProjectTree = cloneDeep(this.projectTree);
            this.getTreeDataInit();
          }
        })
        .catch(() => {
          this.$message.success('导出取消成功');
        });
    },
    /**
     * 获取树数据之后得初始操作
     */
    getTreeDataInit() {
      const projectTree = this.projectTree;
      if (projectTree.length) {
        this.$nextTick(() => {
          this.setExpandedKeys(this.projectTree);
          this.getViewReport(projectTree[0].children[0]);
          this.currentInfo = projectTree[0].children[0];
          this.treeSelectedKeys = [this.currentInfo?.key];
          this.getStyleList(projectTree[0]);
          this.allCheckedNum = this.selectedKeys.length;
        });
      }
    },
    /**
     * 获取样式切换数据
     * @param data
     */
    getStyleList(data: ReportTreeItem) {
      const cloneData = cloneDeep(data);
      this.styleChangeList = [];
      this.styleChangeList.push(cloneData.children[3]);
      this.styleChangeList.push(cloneData.children[4]);
      const lastLevelList: ReportTreeItem[] =
        cloneData.children[cloneData.children.length - 1].children[0].children;
      this.styleChangeList = this.styleChangeList.concat(lastLevelList);
    },
    /**
     * 点击样式切换按钮
     */
    handlerStyle() {
      this.styleChangeVisible = true;
      this.getTableHeaderWidth();
    },
    /**
     * 处理报表树数据主要加唯一key值
     */
    handlerReportTree(data: ReportTreeItem[], tempNum = 0) {
      return data.map((item: ReportTreeItem, index) => {
        tempNum += index;
        if (item.children.length) {
          item.children = this.handlerReportTree(item.children, tempNum);
        }
        item.key = item.parentId + item.name + item.reportName + tempNum;
        return item;
      });
    },
    setStyle(flag) {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要切换的项目');
      }
      this.setStyleAfterOriginData();
      if (flag === 'H') {
        this.styleChangeList.forEach((item: ReportTreeItem) => {
          if (this.selectedRowKeys.includes(item.reportName as string)) {
            item.storageFormat = 'H';
            item.active = true;
          }
        });
      } else {
        this.styleChangeList.forEach((item: ReportTreeItem) => {
          if (this.selectedRowKeys.includes(item.reportName as string)) {
            item.storageFormat = 'V';
          }
        });
      }
      this.clearSelectedRowKeys();
    },
    /**
     * 设置横竖版之后的原数据
     */
    setStyleAfterOriginData() {
      const setStyleOriginData = this.setStyleOriginData;
      const selectRows = cloneDeep(this.selectedRows);
      this.selectedRowKeys.forEach((key, index) => {
        if (!setStyleOriginData[key]) {
          setStyleOriginData[key] = selectRows[index];
        }
      });
      this.setStyleOriginData = setStyleOriginData;
    },
    // 保存样式
    saveStyle() {
      this.updateStyleList(this.projectTree);
      this.styleChangeVisible = false;
      this.setStyleOriginData = {};
      console.log('===========', this.projectTree);
    },
    cancelSaveStyle() {
      this.styleChangeVisible = false;
      const keys = Object.keys(this.setStyleOriginData);
      const setStyleOriginData = this.setStyleOriginData;
      if (!keys.length) return;
      const styleChangeList = cloneDeep(this.styleChangeList);
      styleChangeList.forEach((item: ReportTreeItem, index) => {
        if (keys.includes(item.reportName as string)) {
          this.styleChangeList.splice(
            index,
            1,
            setStyleOriginData[item.reportName as string]
          );
        }
      });
    },
    updateStyleList(data) {
      const styleChangeList = this.styleChangeList;
      const styleChangeListKeys = styleChangeList.map(
        (item) => item.reportName
      );
      data.forEach((item) => {
        if (styleChangeListKeys.includes(item.reportName)) {
          item.storageFormat = styleChangeList.filter(
            (x) => x.reportName === item.reportName
          )[0].storageFormat;
          if (this.currentInfo?.key === item.key) {
            this.getViewReport(item);
          }
        }
        if (item.children && item.children.length) {
          this.updateStyleList(item.children);
        }
      });
    },
    /**
     * 设置树形数据全部展开以及全选
     * @param data
     */
    setExpandedKeys(data) {
      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        this.expandedKeys.splice(this.expandedKeys.length, 0, item.key);
        this.selectedKeys.splice(this.selectedKeys.length, 0, item.key);
        if (item.children && item.children.length) {
          this.setExpandedKeys(item.children);
        }
      }
    },
    /**
     * 获取报表
     */
    getViewReport(data: ReportTreeItem) {
      if (!data.storageFormat) return;
      const time = new Date().getTime();
      let url = data.storageFormat === 'H' ? data.hengBanUrl : data.shuBanUrl;
      if (!url) return;
      url += `?t=${time}`;
      this.fileUrl = `${
        process.env.VUE_APP_YSF_URL
      }/static/pdf/web/viewer.html?file=${encodeURIComponent(url)}`;
    },
    /**
     * 左侧数结构每列的点击事件
     * @param keys
     * @param node
     */
    clickSelectTree(
      keys: string[],
      { node }: { node: { dataRef: ReportTreeItem; expanded: boolean } }
    ) {
      const data = node.dataRef;
      if (!data.children.length) {
        this.currentInfo = data;
        this.getViewReport(data);
      }
      const nodeKey = node.dataRef.key as string;
      if (!node.expanded) {
        this.expandedKeys.push(nodeKey);
      } else {
        this.expandedKeys = this.expandedKeys.filter((key: string) => {
          return key !== nodeKey;
        });
      }
    },
    // 点击导出按钮操作
    clickExport() {
      if (!this.selectedKeys.length) {
        this.$message.warning('请选择需要导出的投标清单');
        return;
      }
      if (
        this.isAdmin &&
        this.freeExportSurplusNum === 0 &&
        this.exportSpaceTimes === 0
      ) {
        this.tipVisible = true;
      } else {
        this.tipVisible = true;
        this.modalTitle = '导出确认';
        this.okText = '导出';
      }
    },
    // 弹框点击确定按钮
    handlerSure() {
      if (
        this.isAdmin &&
        this.freeExportSurplusNum === 0 &&
        this.exportSpaceTimes === 0
      ) {
        this.tempListSave();
        this.goBuy();
      } else if (this.freeExportSurplusNum || this.exportSpaceTimes) {
        this.tipVisible = false;
        this.downloadProject();
      }
    },
    downloadProject() {
      // if (
      //   this.allCheckedNum === this.selectedKeys.length &&
      //   JSON.stringify(this.originalProjectTree) ===
      //     JSON.stringify(this.projectTree)
      // ) {
      //   this.downloadConstruct('zip');
      // } else {
      this.constructCompositeBidDetail();
      // }
    },
    getTreeSelectData() {
      const projectTree = cloneDeep(this.projectTree);
      const selectedKeys = this.selectedKeys;
      function treeLoop(treeData) {
        let children = [] as ReportTreeItem[];
        let isSelect = false;
        for (let index in treeData) {
          const tree = treeData[index];
          if (selectedKeys.includes(tree.key)) {
            delete tree.key;
            children.push(tree);
            isSelect = true;
          } else {
            if (tree.children?.length) {
              let [subChild, isChildSelect] = treeLoop(tree.children);
              if (isChildSelect) {
                isSelect = isChildSelect;
                tree.children = subChild;
                delete tree.key;
                children.push(tree);
              }
            }
          }
        }
        return [children, isSelect];
      }
      return treeLoop(projectTree)[0];
    },
    // 如若导出但需要订购次数,数据临时保存
    tempListSave() {
      sessionStorage.setItem('projectList', JSON.stringify(this.projectTree));
    },
    noHandle() {
      if (this.isFullScreen) {
        document.exitFullscreen();
        this.isFullScreen = false;
      }
      this.$emit('update:visible', false);
    },
    /**
     * 获取导出权益次数
     */
    getExportSpaceTimes() {
      const spaceId = this.spaceId;
      if (!spaceId) return;
      getExportTimes(spaceId).then(({ status, result }) => {
        if (status === 200) {
          this.exportSpaceTimes = Number(result);
        }
      });
    },
    /**
     * 立即订购
     */
    goBuy() {
      if (this.projectSource === '1') {
        this.goBuyDetail();
      } else {
        history.pushState(null, '', '/sub2/console/home');
      }
    },
    // 去认证
    goAuth() {
      history.pushState(null, '', '/sub2/console/home');
    },
    /**
     * 导出数据
     * @param type
     */
    downloadConstruct(type = 'zip') {
      if (this.isThereReplace) {
        this.$message.warning('导出失败：项目清单有变更请先进行重新计价');
        return;
      }

      const constructSequenceNbr = this.projectBidSequenceNbr;
      const formData = new FormData();
      formData.append('constructSequenceNbr', constructSequenceNbr);
      // formData.append('fileType', type);
      this.tipLoading = true;

      coccMtdsResource_1_project_constructsByConstructSequenceNbrBidDetail(
        constructSequenceNbr,
        formData
      )
        .then((res: any) => {
          // downloadFileByUrl(res.result, this.projectName, type)
          // window.location.href = res.result;
          const projectSpaceDetail = this.projectSpaceDetail;
          const exportNum = projectSpaceDetail
            ? projectSpaceDetail.exportNumber
            : 0;
          this.SET_EXPORT_NUMBER(exportNum + 1);
          let name = res.result.split('com')[1].toString();
          new AliOss().download(
            name.substring(1, name.length),
            this.projectName,
            type
          );
          this.exportVisible = false;
        })
        .finally(() => {
          this.tipLoading = false;
          this.tipVisible = false;
        });
    },
    /**
     * 下载次数申请
     */
    exportNumApprove() {
      const spaceId = this.spaceId;
      if (!spaceId || !this.isApply) return;
      exportApprove(spaceId).then((res) => {
        if (res.status === 200) {
          this.$message.success('申请成功');
          this.exportApproveStatus = ExportApproveStatus.WAIT;
        } else {
          this.$message.error(res.result);
        }
      });
    },
    /**
     * 获取申请状态
     */
    async syncGetExportApproveResult() {
      const spaceId = this.spaceId;
      if (!spaceId || this.isAdmin || this.freeExportSurplusNum) return;
      const { status, result } = await getExportApproveResult(spaceId);
      if (status === 200) {
        this.exportApproveStatus = result;
      }
    },
    setDialogHeight() {
      this.$nextTick(() => {
        const tableEl = document.querySelector(
          '.ant-table-fixed'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.style-list');
    },
    exportCancel() {
      exportCancel(this.projectBidSequenceNbr).then(({ status, result }) => {
        if (status === 200 && result) {
          this.$message.success('导出终止成功');
          this.removeCurrentExportList(this.projectSequenceNbr);
          this.closeProcessShow();
        }
      });
    },
    // 最小化
    minimality() {
      this.exportVisible = false;
      this.$store.commit('SET_IS_EXPORT_MINIMALITY', true);
      this.$emit('update:visible', false);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    clearSelectedRowKeys() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    downloadProcess() {
      const projectBidSequenceNbr = this.projectBidSequenceNbr;
      const projectSequenceNbr = this.projectSequenceNbr;
      if (!projectBidSequenceNbr) return;
      downloadProcess(projectBidSequenceNbr)
        .then((res: any) => {
          const {
            status,
            result: { currentNum, total },
          } = res;
          if (status === 200 && res.result) {
            const process = Number(
              ((Math.floor((currentNum / total) * 100) / 100) * 100).toFixed()
            );
            this.SET_EXPORT_PROCESS(process);
            if (process >= 100) {
              if (projectSequenceNbr === this.$route.query.sequenceNbr) {
                // 同个项目下更改清单生成状态
                this.SET_GENERATELISTSTATUS(2);
              }
              this.selectUrl();
              this.removeCurrentExportList(projectSequenceNbr);
              this.closeProcessShow();
            }
          } else {
            this.exportError(projectSequenceNbr);
          }
        })
        .catch((err) => {
          this.exportError(projectSequenceNbr);
        });
    },
    exportError(projectSequenceNbr) {
      this.$message.error('导出生成失败');
      this.closeProcessShow();
      this.removeCurrentExportList(projectSequenceNbr);
    },
    /**
     * 关闭所有进度操作
     */
    closeProcessShow() {
      this.closeProcess();
      this.exportVisible = false;
      this.EXPORT_PROCESS_STATUS_INIT();
    },
    exportInit() {
      this.initVisible = false;
      exportInit(this.projectBidSequenceNbr).then((res) => {
        if (res.status === 200 && res.result) {
          this.projectTree = this.originalProjectTree;
          this.getTreeDataInit();
          this.$message.success('初始化成功');
        }
      });
    },
    selectUrl(type = 'zip') {
      const projectBidSequenceNbr = this.projectBidSequenceNbr;
      exportConstructDetailUrl(projectBidSequenceNbr, type).then((res: any) => {
        console.log('生成URL', res.result);
        if (res.status === 200 && res.result) {
          const projectSpaceDetail = this.projectSpaceDetail;
          const exportNum = projectSpaceDetail
            ? projectSpaceDetail.exportNumber
            : 0;
          this.getExportSpaceTimes();
          this.SET_EXPORT_NUMBER(exportNum + 1);
          if (!this.freeExportSurplusNum)
            this.exportApproveStatus = ExportApproveStatus.NOT;
          let name = res.result.split('com')[1].toString();
          new AliOss().download(
            name.substring(1, name.length),
            projectZipName || this.projectName,
            type
          );
        }
      });
    },
    // 轮询正在生成导出
    setIntervalInfo() {
      if (Date.now() - this.currentTime > 3000) {
        this.currentTime = Date.now();
        this.downloadProcess();
      }
      if (this.start) {
        this.timer = requestAnimationFrame(this.setIntervalInfo);
      }
    },
    goBuyDetail() {
      if (this.downloadType === '1') {
        history.pushState(null, '', process.env.VUE_APP_BUY_SPACE_DETAIL);
      } else if (this.downloadType === '0') {
        history.pushState(null, '', process.env.VUE_APP_BUY_EXPORT_DETAIL);
      } else {
        history.pushState(null, '', '/sub2/console/home');
      }
    },
    goEdit() {
      this.editorVisible = true;
    },
    /**
     * 进度开始
     */
    startProcess() {
      const projectSequenceNbr = this.projectSequenceNbr;
      const currentExportList = this.$store.state.project.currentExportList;
      if (currentExportList.includes(projectSequenceNbr)) {
        console.log('已经正在进行====》', projectSequenceNbr);
        return;
      }
      projectZipName = this.projectName;
      this.SET_IS_EXPORT_GENERATE_LIST(true);
      this.start = true;
      this.addCurrentExportList(projectSequenceNbr);
      this.setIntervalInfo();
    },
    closeProcess() {
      this.start = false;
      this.timer && cancelAnimationFrame(this.timer);
    },
    // 导出生成清单
    constructCompositeBidDetail() {
      const params = this.getTreeSelectData();
      if (!params.length) return;
      constructCompositeBidDetail(params[0]).then((res) => {
        if (res.status === 200) {
          this.exportVisible = true;
          projectZipName = this.projectName;
          this.startProcess();
          this.projectOperationStatusInit();
        } else {
          this.$message.error('导出生成失败');
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFullScreen, false);
    // this.start = false;
    // this.timer && cancelAnimationFrame(this.timer);
  },
});
